import Vue from 'vue';
import { http } from '@/services/Plugins/Axios';
import { Auth } from '@/services/General/Auth';

import store from '@/store';
import router from '@/router';

// Import main template
import App from '@/App';

// Import styles
import '@/services/Styles';

// Locales
import i18n from './i18n';

// Import plugins
import '@/services/Plugins';

// Import prototypes
import '@/services/Prototypes/userPermissions';

// Disable development protocol warning
Vue.config.productionTip = false;

// Set headers token
const token = Auth.getAuthToken() || '';
if (token) {
  http.setAuthToken(token);
}

// Create app
new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
