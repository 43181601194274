export const BRANDS = [
  {
    name: 'MarfaTech',
    brand: 'Marfatech',
    url: 'https://marfa-tech.com',
    icon: 'marfa',
  },
  {
    name: 'DuxGroup',
    brand: 'Duxgroup',
    url: 'https://duxgroup.com',
    icon: 'dux',
  },
  {
    name: 'SokoloVTech',
    brand: 'Sokolov',
    url: 'https://sokolov.com',
    icon: 'dux',
  },
];
