import { AuthGuard } from '@/router/Auth-guard';

import Estimation from '@/views/Estimation/Main';

import Questionary from '@/views/Estimation/Questionary/AdminQuestionary';
import ArchiveJournal from '@/views/Estimation/Questionary/Archive/AdminArchiveJournal';
import QuestionaryTesting from '@/views/Estimation/Questionary/Testing/AdminQuestionaryTesting';
import QuestionaryJournal from '@/views/Estimation/Questionary/Journal/AdminQuestionaryJournal';
import Reports from '@/views/Estimation/Reports/AdminReports';
import Users from '@/views/Estimation/Users/AdminUsers';
import Settings from '@/views/Estimation/Settings/AdminSettings';
import Templates from '@/views/Estimation/Templates/AdminTemplates';

import Dictionary from '@/views/Estimation/Dictionary/Main';
import Competencies from '@/views/Estimation/Dictionary/Tabs/Competencies';
import Positions from '@/views/Estimation/Dictionary/Tabs/Positions';
import Subdivisions from '@/views/Estimation/Dictionary/Tabs/Subdivisions';
import Employees from '@/views/Estimation/Dictionary/Tabs/Employees';

export default [
  {
    path: '/estimation',
    name: 'estimation',
    component: Estimation,
    redirect: { name: 'home' },
    children: [
      {
        path: 'questionary',
        component: Questionary,
        beforeEnter: AuthGuard.isAdmin,
        children: [
          {
            path: 'archive',
            name: 'questionary:archive',
            component: ArchiveJournal,
            beforeEnter: AuthGuard.isAdmin,
          },
          {
            path: 'testing',
            name: 'questionary:testing',
            component: QuestionaryTesting,
            beforeEnter: AuthGuard.isAdmin,
          },
          {
            path: ':id',
            props: true,
            name: 'questionary:detail',
            component: QuestionaryJournal,
            beforeEnter: AuthGuard.isAdmin,
          },
          {
            path: '',
            name: 'home',
            component: QuestionaryJournal,
            beforeEnter: AuthGuard.isAdmin,
          },
        ],
      },
      {
        path: 'reports',
        name: 'reports',
        component: Reports,
        beforeEnter: AuthGuard.isAdmin,
      },
      // Тестирование сотрудника
      {
        path: 'dictionary',
        name: 'dictionary',
        redirect: { name: 'dictionary:competencies' },
        component: Dictionary,
        beforeEnter: AuthGuard.isAdmin,
        children: [
          {
            path: 'competencies',
            name: 'dictionary:competencies',
            component: Competencies,
            beforeEnter: AuthGuard.isAdmin,
          },
          {
            path: 'positions',
            name: 'dictionary:positions',
            component: Positions,
            beforeEnter: AuthGuard.isAdmin,
          },
          {
            path: 'subdivisions',
            name: 'dictionary:subdivisions',
            component: Subdivisions,
            beforeEnter: AuthGuard.isAdmin,
          },
          {
            path: 'employees',
            name: 'dictionary:employees',
            component: Employees,
            beforeEnter: AuthGuard.isAdmin,
          },
        ],
      },
      {
        path: 'users',
        name: 'users',
        component: Users,
        beforeEnter: AuthGuard.isAdmin,
      },
      {
        path: 'settings',
        name: 'settings',
        component: Settings,
        beforeEnter: AuthGuard.isAdmin,
      },
      {
        path: 'templates',
        name: 'templates',
        component: Templates,
        beforeEnter: AuthGuard.isAdmin,
      },
      {
        path: '*',
        redirect: { name: 'home' },
      },
    ],
  },
];
