export const HelperRegEx = {
  replaceParamsFromRequestUrl: (params = {}, string = '') => {
    let result = string;
    if (typeof params === 'object' && Object.keys(params).length) {
      for (const [key = '', value = ''] of Object.entries(params)) {
        if (key !== '' && value !== '') {
          const pattern = `({${key}})`;
          const regular = new RegExp(pattern, 'g');
          result = result.replace(regular, value);
        }
      }
    }
    return result;
  },
  searchMatchOfWhiteListActions: (action, whiteListPrefixOfActions) => {
    const pattern = `${whiteListPrefixOfActions.join('|')}`;
    const regular = new RegExp(pattern, 'gi');
    return action.match(regular);
  },
};
