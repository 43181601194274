import { http } from '@/services/Plugins/Axios';
import { Auth } from '@/services/General/Auth';
import { Api } from '@/services/General/Api';
import { ENDPOINTS_AUTH, ENDPOINTS_USERS } from '@/services/Endpoints';
import router from '@/router';

import { Notification } from '@/services/General/Notification';

const clearAuthData = () => {
  Auth.removeAuthToken();
  http.setAuthToken();
  router.push({ name: 'login' });
};

export default {
  namespaced: true,
  state: {
    currentUserInfo: {
      id: null,
      employeeId: null,
      login: null,
      roles: [],
      brandList: [],
    },
    token: Auth.getAuthToken() || '',
    errors: '',
  },
  mutations: {
    setAuthToken(state, token = '') {
      state.token = token;
    },
    setCurrentUserInfo(state, info = {}) {
      const { id = null, employeeId = null, email = null, roles = [], brandList = [] } = info;
      state.currentUserInfo = { id, employeeId, email, roles, brandList };
    },
  },
  actions: {
    async getCurrentUserInfo({ commit }) {
      try {
        await Api.get({ requestUrl: ENDPOINTS_USERS.USERS_GET_INFO, data: {} }).then(
          ({ data } = {}) => {
            commit('setCurrentUserInfo', data);
          },
        );
      } catch (error) {
        throw new Error(error);
      }
    },
    async switchBrand(_, { name = null } = {}) {
      try {
        await Api.post({
          requestUrl: ENDPOINTS_USERS.USERS_SWITCH_BRAND,
          data: { payload: { name } },
          notify: false,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async loginUser({ commit, rootState }, { email = '', password = '', locale = '' } = {}) {
      try {
        await Api.post({
          requestUrl: ENDPOINTS_AUTH.LOGIN,
          data: {
            payload: {
              email,
              password,
              locale,
            },
          },
          notify: false,
        }).then(({ data } = {}) => {
          const { token } = data;
          if (token) {
            Auth.setAuthToken(token);
            http.setAuthToken(token);
            commit('setAuthToken', token);
            router.push({ name: rootState.routeFrom || 'home' });
          }
        });
      } catch (error) {
        Notification.error(error);
        throw new Error(error);
      }
    },
    async logoutUser({ commit, state }) {
      const { id = null } = state.currentUserInfo;
      if (id) {
        try {
          await Api.post({
            requestUrl: ENDPOINTS_AUTH.LOGOUT,
            data: {
              payload: {
                id,
              },
            },
            notify: false,
          }).then(() => {
            commit('setCurrentUserInfo');
            commit('setAuthToken');
            clearAuthData();
          });
        } catch (error) {
          Notification.error(error);
          throw new Error(error);
        }
      }
    },
    setAuthToken({ commit }, token) {
      commit('setAuthToken', token);
      if (!token) {
        clearAuthData();
      }
    },
    async checkEmailForRestorePassword({ commit }, { email = '' } = {}) {
      try {
        await Api.post({
          requestUrl: ENDPOINTS_AUTH.RESET_PASSWORD_CHECK_EMAIL,
          data: {
            payload: {
              email,
            },
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async restorePassword({ commit }, { token = '', password = '', confirmPassword = '' } = {}) {
      try {
        await Api.post({
          requestUrl: ENDPOINTS_AUTH.RESET_PASSWORD_RESTORE,
          data: {
            payload: {
              token,
              password,
              confirmPassword,
            },
          },
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    async resetPassword({ dispatch, commit }, { password = '', confirmPassword = '' } = {}) {
      try {
        await Api.patch({
          requestUrl: ENDPOINTS_AUTH.RESET_PASSWORD_RESET,
          data: {
            payload: {
              password,
              confirmPassword,
            },
          },
        }).then(() => {
          commit('setCurrentUserInfo');
          commit('setAuthToken');
          clearAuthData();
        });
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  getters: {
    activeUserBrand(state) {
      return state.currentUserInfo.brandList.find((brand) => brand.active);
    },
    userBrands(state) {
      return state.currentUserInfo.brandList.map((brand) => brand.name);
    },
  },
};
